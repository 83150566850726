var wordpressUrl = "https://www.georgiamainstreet.org/";

// Scope below code to "our main street page that has a map"
// if ($("body").hasClass("page-template-page-impact") && $("#map").length) {
//   // Mapper Class

//   var Mapper = function () {
//     this.geocoder = new google.maps.Geocoder(); // google maps geocoder object
//    // this.map; // google map object
//     this.markers = []; // markers array
//     this.center = null;
//     this.zoom = 10;
//     this.minZoom = 3;
//     this.locations = []; // locations from database - JSON.stringify array of objects [{},{},{}] - instanceName.locations = $.parseJSON(locations)
//     this.search = ""; // search string
//     this.location = "";
//     this.ready = false; // true when getRawLocations returns successfully
//     this.infowindow = new google.maps.InfoWindow();

//     (this.initialize = function () {
//       // visit http://googlemaps.github.io/js-samples/styledmaps/wizard/index.html
//       var styles = [
//         {
//           featureType: "landscape",
//           stylers: [{ gamma: 1 }, { weight: 0.1 }, { color: "#c1b973" }],
//         },
//         {
//           featureType: "road",
//           stylers: [{ color: "#c1b973" }, { lightness: -12 }],
//         },
//         {
//           featureType: "water",
//           stylers: [{ color: "#948e66" }],
//         },
//       ];

//       var styledMap = new google.maps.StyledMapType(styles, {
//         name: "Styled Map",
//       });

//       var mapOptions = {
//         center: this.center,
//         zoom: this.zoom,
//         minZoom: this.minZoom,
//         disableDefaultUI: true,
//         scrollwheel: false,
//         // location: this.location
//         mapTypeIds: [google.maps.MapTypeId.ROADMAP, "map_style"],
//       };

//       // initialize map

//       this.map = new google.maps.Map(
//         document.getElementById("map"),
//         mapOptions
//       );

//       this.map.mapTypes.set("map_style", styledMap);
//       this.map.setMapTypeId("map_style");
//     }),
//       (this.setMapCenter = function () {
//         var self = this;

//         // use geocoder object to set map center

//         this.geocoder.geocode(
//           { address: this.search },
//           function (results, status) {
//             if (status == google.maps.GeocoderStatus.OK) {
//               self.center = results[0].geometry.location;

//               // reinitialize map

//               self.initialize();

//               // set the zoom
//               self.map.setZoom(self.zoom);

//               self.placeMarkers();
//             } else {
//               //console.log(status); // handle no results
//             }
//           }
//         );
//       }),
//       (this.placeMarkers = function (tier) {
//         tier = tier || false;
//         var self = this;
//         var locations;

//         // delete the current markers

//         this.deleteMarkers();

//         if (
//           tier &&
//           tier !== "All Tiers" &&
//           tier !== "Tier 1: Downtown Start-Up Program"
//         ) {
//           locations = this.locations.filter(function (location) {
//             if (tier === location.tier) {
//               return location;
//             }

//             return false;
//           });
//         } else if (tier == "All Tiers") {
//           locations = this.locations.filter(function (location) {
//             return location;
//           });
//         } else {
//           // foreach location
//           var currentLocation = this.location;

//           locations = this.locations.filter(function (location) {
//             if (currentLocation) {
//               if (location.location === currentLocation) {
//                 return location;
//               }

//               return false;
//             }

//             return location;
//           });
//         }

//         locations.forEach(function (location) {
//           // if we have latitude and longitude

//           if (location.latitude && location.longitude) {
//             var position = new google.maps.LatLng(
//               location.latitude,
//               location.longitude
//             );

//             // add marker to map

//             if (location.tier !== "Tier 4: Downtown Affiliate Network") {
//               self.addMarker(position, location);

//               self.addModal(location);
//             }
//           }
//         });
//       }),
//       (this.addMarker = function (position, location) {
//         var self = this;

//         // setup the marker object
//         var content = '<div class="info-window">';
//         content += '<div class="col-left">';
//         content += "<h2>" + location.title + "</h2>";
//         content += "<p>Year Designated:</p>";
//         content += "<p>" + location.incorporated + "</p>";
//         content += "<p>Population:</p>";
//         content += "<p>" + location.population + "</p>";
//         //content += '<button class="see-impact" data-modal="'+location.modalId+'">See Impact</button>';
//         content += "</div>";
//         content += '<div class="col-right">';
//         content += '<img src="' + location.mapThumbnail + '"/>' + "<br>";
//         content += "</div>";
//         content += "</div>";

//         var markerImg = {};

//         switch (location.tier) {
//           // case 'Tier 1: Our-Town Program':
//           // 	markerImg.url = '/wp-content/themes/looklisten/dist/images/icons/map-pin-lightgreen.png';
//           // 	break;
//           case "Tier 1: Downtown Affiliate Network":
//             markerImg.url =
//               "/wp-content/themes/looklisten/dist/images/icons/map-pin-green.png";
//             break;
//           case "Tier 2: Classic Main Street Program":
//             markerImg.url =
//               "/wp-content/themes/looklisten/dist/images/icons/map-pin-orange.png";
//             break;
//           case "Tier 3: Georgia's Exceptional Main Streets":
//             markerImg.url =
//               "/wp-content/themes/looklisten/dist/images/icons/map-pin-blue.png";
//             break;
//           // case 'Tier 4: Downtown Affiliate Network':
//           // 	markerImg.url = '/wp-content/themes/looklisten/dist/images/icons/map-pin-blue.png';
//           // 	break;
//         }

//         if ($(window).width() > 767) {
//           markerImg.scaledSize = new google.maps.Size(25, 35);
//         } else {
//           markerImg.scaledSize = new google.maps.Size(10, 15);
//         }

//         var marker = new google.maps.Marker({
//           position: position, // my latlng
//           map: this.map, // map
//           title: location.title, // appears in tool tip
//           icon: markerImg,
//         });

//         // store the marker object in markers array

//         this.markers.push(marker);

//         // add a listener for each marker

//         google.maps.event.addListener(marker, "click", function () {
//           self.infowindow.setContent(content); // ensures only one will be open at a time
//           self.infowindow.open(self.map, marker);
//         });

//         this.reCenterMap();
//       }),
//       (this.deleteMarkers = function () {
//         // remove all markers

//         for (var i = 0; i < this.markers.length; i++) {
//           this.markers[i].setMap(null);
//         }

//         // reset markers array

//         this.markers = [];
//       }),
//       (this.addModal = function (location) {
//         var modalContent = '<div class="modal-content">';
//         modalContent += '<div class="col-left">';
//         modalContent += '<span id="closeModal" class="close">X</span>';
//         modalContent += '<img src="' + location.mapThumbnail + '"/>';
//         modalContent += "</div>";
//         modalContent += '<div class="col-right">';
//         modalContent += '<div class="title-container">';
//         modalContent += '<div class="section-title-wrapper">';
//         modalContent += '<div class="ticket">';
//         modalContent += '<h2 class="section-title">' + location.title + "</h2>";
//         modalContent += "</div>";
//         modalContent += "</div>";
//         modalContent += "</div>";
//         modalContent += '<div class="table">';
//         modalContent += '<div class="table-row">';
//         modalContent += '<div class="table-cell">';
//         modalContent += "Year Designated";
//         modalContent += "</div>";
//         modalContent += '<div class="table-cell">';
//         modalContent += location.incorporated;
//         modalContent += "</div>";
//         modalContent += "</div>";
//         modalContent += '<div class="table-row">';
//         modalContent += '<div class="table-cell">';
//         modalContent += "Population:";
//         modalContent += "</div>";
//         modalContent += '<div class="table-cell">';
//         modalContent += location.population;
//         modalContent += "</div>";
//         modalContent += "</div>";
//         // 		modalContent += '<div class="table-row">';
//         // 	modalContent += '<div class="table-cell">';
//         // 				modalContent += 'Private Investment:';
//         // 	modalContent += '</div>';
//         // 	modalContent += '<div class="table-cell">';
//         // 		modalContent += '$'+location.privateInvestment;
//         // 	modalContent += '</div>';
//         // 		modalContent += '</div>';
//         modalContent += '<div class="table-row">';
//         modalContent += '<div class="table-cell">';
//         modalContent += "Public & Private Investment:";
//         modalContent += "</div>";
//         modalContent += '<div class="table-cell">';
//         modalContent += "$" + location.investment;
//         modalContent += "</div>";
//         modalContent += "</div>";
//         modalContent += '<div class="table-row">';
//         modalContent += '<div class="table-cell">';
//         modalContent += "Net New Jobs Created:";
//         modalContent += "</div>";
//         modalContent += '<div class="table-cell">';
//         modalContent += location.newJobsCreated;
//         modalContent += "</div>";
//         modalContent += "</div>";
//         modalContent += '<div class="table-row">';
//         modalContent += '<div class="table-cell">';
//         modalContent += "GA Main Street Tier";
//         modalContent += "</div>";
//         modalContent += '<div class="table-cell">';
//         modalContent += location.tier;
//         modalContent += "</div>";
//         modalContent += "</div>";
//         modalContent += "</div>";
//         modalContent +=
//           '<a href="' +
//           location.communityUrl +
//           '">' +
//           location.communityUrl +
//           "</a>";
//         modalContent += "</div>";
//         modalContent += "</div>";

//         var mapModal = document.createElement("div");
//         mapModal.className = "modalDialog";
//         mapModal.id = location.modalId;
//         mapModal.innerHTML = modalContent;

//         document.getElementById("container").appendChild(mapModal);
//       }),
//       (this.reCenterMap = function () {
//         var bounds = new google.maps.LatLngBounds();

//         for (var i = 0; i < this.markers.length; i++) {
//           bounds.extend(this.markers[i].getPosition());
//         }
//       });
//   };
// }

$(function () {
  // document.ready

  // open and close mobile menu

  $(".mobile-icon").on("click", function () {
    $(this).toggleClass("active");

    $(".mobile-menu-content").toggleClass("active");
  });

  // add clickable icon to mobile nav items that have a submenu

  $(".mobile-menu-content li.menu-item-has-children").prepend(
    '<div class="sub-menu-mobile-icon"></div>'
  );

  // open and close sub mobile nav

  $(".sub-menu-mobile-icon").on("click", function () {
    $(this)
      .closest(".menu-item-has-children")
      .children("ul.sub-menu")
      .toggleClass("active");

    $(this).toggleClass("active");
  });

  // when click on info window button open map modal

  $(document).on("click", ".see-impact", function () {
    $(".modalDialog").removeClass("active");

    var modalId = $(this).attr("data-modal");

    $(".modalDialog#" + modalId + "").addClass("active");
  });

  // when click on close icon on modal close that modal

  $(document).on("click", "#closeModal", function () {
    var modalId = $(this).closest(".modalDialog").attr("id");

    $(".modalDialog#" + modalId + "").removeClass("active");
  });

  // Comments
  $(".comment-trigger").on("click", function (e) {
    e.preventDefault();

    $(this).parent().next(".comments").toggleClass("active slideDown");
  });

  // Comments Reply
  $(".comment-reply-link").on("click", function (e) {
    e.preventDefault();

    $(this)
      .parents(".comment-body")
      .next(".comment-respond")
      .toggleClass("active expandOpen");
  });

  // Social Reveal
  $(".shares").on("click", function (e) {
    e.preventDefault();

    $(this).siblings("a.fb").toggleClass("active expandOpen");
    $(this).siblings("a.tweet").delay("1000").toggleClass("active expandOpen");
    $(this).siblings("a.insta").delay("2000").toggleClass("active expandOpen");
  });

  // home page hero slide

  // $(".slider-home-hero").slick({
  //   mobileFirst: true,
  //   autoplay: false,
  //   responsive: [
  //     {
  //       breakpoint: 1024,
  //       settings: {
  //         slidesToShow: 1,
  //         slidesToScroll: 1,
  //       },
  //     },
  //     {
  //       breakpoint: 767,
  //       settings: {
  //         slidesToShow: 1,
  //         slidesToScroll: 1,
  //       },
  //     },
  //     {
  //       breakpoint: 375,
  //       settings: {
  //         slidesToShow: 1,
  //         slidesToScroll: 1,
  //       },
  //     },
  //   ],
  // });

  // $(".slider-home-hero").addClass("active");

  // home page main streets of the month slider

  // $(".slider-home-ll-sotm").slick({
  //   mobileFirst: true,
  //   responsive: [
  //     {
  //       breakpoint: 1024,
  //       settings: {
  //         slidesToShow: 1,
  //         slidesToScroll: 1,
  //       },
  //     },
  //     {
  //       breakpoint: 767,
  //       settings: {
  //         slidesToShow: 1,
  //         slidesToScroll: 1,
  //       },
  //     },
  //     {
  //       breakpoint: 375,
  //       settings: {
  //         slidesToShow: 1,
  //         slidesToScroll: 1,
  //       },
  //     },
  //   ],
  // });

  // $(".slider-home-ll-sotm").addClass("active");

  // duplicate of home page main streets of the month slider for community post detail page

  // $(".slider-community-ll-sotm").slick({
  //   mobileFirst: true,
  //   responsive: [
  //     {
  //       breakpoint: 1024,
  //       settings: {
  //         slidesToShow: 2,
  //         slidesToScroll: 1,
  //       },
  //     },
  //     {
  //       breakpoint: 767,
  //       settings: {
  //         slidesToShow: 2,
  //         slidesToScroll: 1,
  //       },
  //     },
  //     {
  //       breakpoint: 375,
  //       settings: {
  //         slidesToShow: 1,
  //         slidesToScroll: 1,
  //       },
  //     },
  //   ],
  // });

  // $(".slider-community-ll-sotm").addClass("active");

  // // about page team slider

  // $(".team-slider").slick({
  //   mobileFirst: true,
  //   responsive: [
  //     {
  //       breakpoint: 1024,
  //       settings: {
  //         slidesToShow: 4,
  //         slidesToScroll: 1,
  //       },
  //     },
  //     {
  //       breakpoint: 767,
  //       settings: {
  //         slidesToShow: 2,
  //         slidesToScroll: 1,
  //       },
  //     },
  //     {
  //       breakpoint: 375,
  //       settings: {
  //         slidesToShow: 1,
  //         slidesToScroll: 1,
  //       },
  //     },
  //   ],
  // });

  // $(".team-slider").addClass("active");

  // // design services page hero slider

  // $(".slider-services-hero").slick({
  //   mobileFirst: true,
  //   responsive: [
  //     {
  //       breakpoint: 1024,
  //       settings: {
  //         slidesToShow: 2,
  //         slidesToScroll: 1,
  //       },
  //     },
  //     {
  //       breakpoint: 767,
  //       settings: {
  //         slidesToShow: 2,
  //         slidesToScroll: 1,
  //       },
  //     },
  //     {
  //       breakpoint: 375,
  //       settings: {
  //         slidesToShow: 1,
  //         slidesToScroll: 1,
  //       },
  //     },
  //   ],
  // });

  // $(".slider-services-hero").addClass("active");

  // accordion on our main streets page

  $(".accordion").on("click", function () {
    $(this).children(".accordion-content").toggleClass("active");
    $(this).find(".accordian-title-container h4").toggleClass("active");
    
  });

  // ScrollTo Approaches on About Us
  $(".green-section h2").click(function (event) {
    event.preventDefault();

    var organization = $(".approach section:nth-of-type(1)");

    $("html, body").animate({ scrollTop: organization.offset().top });
  });

  $(".blue-section h2").click(function (event) {
    event.preventDefault();

    var promotion = $(".approach section:nth-of-type(2)");

    $("html, body").animate({ scrollTop: promotion.offset().top });
  });

  $(".orange-section h2").click(function (event) {
    event.preventDefault();

    var design = $(".approach section:nth-of-type(3)");

    $("html, body").animate({ scrollTop: design.offset().top });
  });

  $(".brown-section h2").click(function (event) {
    event.preventDefault();

    var restructuring = $(".approach section:nth-of-type(4)");

    $("html, body").animate({ scrollTop: restructuring.offset().top });
  });

  // FB Share Dialogs
  // We bind a new event to our link
  $("a.fb").click(function (e) {
    //We tell our browser not to follow that link
    e.preventDefault();
    //We get the URL of the link
    var loc = $(this).attr("href");
    //We get the title of the link
    var title = encodeURIComponent($(this).attr("title"));
    //We trigger a new window with the Twitter dialog, in the middle of the page
    window.open(
      "https://www.facebook.com/sharer/sharer.php?url=" +
        loc +
        "&text=" +
        title +
        "&",
      "fbwindow",
      "height=450, width=550, top=" +
        ($(window).height() / 2 - 225) +
        ", left=" +
        $(window).width() / 2 +
        ", toolbar=0, location=0, menubar=0, directories=0, scrollbars=0"
    );
  });

  // Twitter Share Dialogs
  // We bind a new event to our link
  $("a.tweet").click(function (e) {
    //We tell our browser not to follow that link
    e.preventDefault();
    //We get the URL of the link
    var loc = $(this).attr("href");
    //We get the title of the link
    var title = encodeURIComponent($(this).attr("title"));
    //We trigger a new window with the Twitter dialog, in the middle of the page
    window.open(
      "http://twitter.com/share?url=" + loc + "&text=" + title + "&",
      "twitterwindow",
      "height=450, width=550, top=" +
        ($(window).height() / 2 - 225) +
        ", left=" +
        $(window).width() / 2 +
        ", toolbar=0, location=0, menubar=0, directories=0, scrollbars=0"
    );
  });

  // Twitter Share Dialogs
  // We bind a new event to our link
  $("a.insta").click(function (e) {
    //We tell our browser not to follow that link
    e.preventDefault();
    //We get the URL of the link
    var loc = $(this).attr("href");
    //We get the title of the link
    var title = encodeURIComponent($(this).attr("title"));
    //We trigger a new window with the Twitter dialog, in the middle of the page
    window.open(
      "http://instagram.com/share?url=" + loc + "&text=" + title + "&",
      "instawindow",
      "height=450, width=550, top=" +
        ($(window).height() / 2 - 225) +
        ", left=" +
        $(window).width() / 2 +
        ", toolbar=0, location=0, menubar=0, directories=0, scrollbars=0"
    );
  });

  // New Map object on /our-main-streets template
  if ($("body").hasClass("layout-impact")) {
    var mapper = new Mapper();

    mapper.locations = $.parseJSON(locations);

    mapper.initialize();

    mapper.search = "macon, georgia";

    mapper.zoom = 7;

    mapper.setMapCenter();

    $('[name="tier"]').click(function () {
      updateTierView($(this).val());
    });
  }

  function updateTierView(tier) {
    if (tier == "Tier_1") {
      mapper.search = "macon, georgia";

      mapper.zoom = 6;

      mapper.location = "Tier 1: Downtown Affiliute Network";

      // mapper.deleteMarkers();

      // mapper.setMapCenter();

      mapper.placeMarkers("Tier 1: Downtown Affiliate Network");

      $(".locations-tiers button").removeClass("active");

      $('[data-tier="Tier 1: Downtown Affiliate Network"]').addClass("active");
    }

    if (tier == "Tier_2") {
      mapper.search = "macon, georgia";

      mapper.zoom = 6;

      mapper.location = "Tier 2: Classic Main Street Program";

      // mapper.deleteMarkers();

      // mapper.setMapCenter();

      mapper.placeMarkers("Tier 2: Classic Main Street Program");

      $(".locations-tiers button").removeClass("active");

      $('[data-tier="Tier 2: Classic Main Street Program"]').addClass("active");
    }

    if (tier == "Tier_3") {
      mapper.search = "macon, georgia";

      mapper.zoom = 6;

      mapper.location = "Tier 3: Georgia's Exceptional Main Streets";

      // mapper.deleteMarkers();

      // mapper.setMapCenter();

      mapper.placeMarkers("Tier 3: Georgia's Exceptional Main Streets");

      $(".locations-tiers button").removeClass("active");

      $('[data-tier="Tier 3: Georgia\'s Exceptional Main Streets"]').addClass(
        "active"
      );
    }

    // if (tier == 'Tier_4') {

    // 	mapper.search = 'georgia';

    // 	mapper.zoom = 6;

    // 	mapper.location = 'Tier 4: Downtown Affiliate Network';

    // 	// mapper.deleteMarkers();

    // 	// mapper.setMapCenter();

    // 	mapper.placeMarkers("Tier 4: Downtown Affiliate Network");

    // 	$('.locations-tiers button').removeClass('active');

    // 	$('[data-tier="Tier 4: Downtown Affiliate Network"]').addClass('active');
    // }

    if (tier == "All_Tiers") {
      mapper.search = "macon, georgia";

      mapper.zoom = 7;

      mapper.location = "All Tiers";

      // mapper.deleteMarkers();

      // mapper.setMapCenter();

      mapper.placeMarkers("All Tiers");

      $(".locations-tiers button").removeClass("active");

      $('[data-tier="All Tiers"]').addClass("active");
    }
  }

  // Financial Tools Custom Post Type Filter
  if ($("body").hasClass("layout-financial")) {
    //set default
    updateView("Community Resources");
    $('[name="financial"]').change(function () {
      updateView($(this).val());
    });
  } else if ($("body").hasClass("layout-resources")) {
    updateResourcesView("Annual Assessment");
    $('[name="resources"]').change(function () {
      updateResourcesView($(this).val());
    });
  } else if ($("body").hasClass("layout-consultants")) {
    updateConsultantsView("Community Development");
    $('[name="consultants"]').change(function () {
      updateConsultantsView($(this).val());
    });
  } else {
  }

  function updateView(financial) {
    $(".financial-heading").removeClass("active");

    $(".financial-articles li").removeClass("active");

    $(".financial-articles > li").each(function () {
      var filterVal = $(this).data("financial");
      if (filterVal.indexOf(financial) > -1) {
        $(this).addClass("active");
      }
    });
  }

  function updateResourcesView(resources) {
    $(".financial-heading").removeClass("active");

    $(".financial-articles li").removeClass("active");

    $(".financial-articles > li").each(function () {
      var filterVal = $(this).data("resources");
      if (filterVal.indexOf(resources) > -1) {
        $(this).addClass("active");
      }
    });
  }

  function updateConsultantsView(consultants) {
    $(".consultant-listing").removeClass("active");

    $(".consultant-listings li").removeClass("active");

    $(".consultant-listings > li").each(function () {
      var filterVal = $(this).data("consultants");
      if (filterVal.indexOf(consultants) > -1) {
        $(this).addClass("active");
      }
    });
  }

  $('a').filter(function(){
    return this.host !== location.host;
  }).attr('target','_blank');

  $("span.close").click(function () {
    $('.freeform').hide();
  });



}); // end doc ready

//Scroll-Utils
var scroll_utils = (function () {
  "use strict";

  var scene_controller = new ScrollMagic.Controller();
  var out = {};

  var countupTrigger = function (trigger_el) {
    var opts = {
      triggerElement: document.querySelector(trigger_el), // First one
      triggerHook: "onEnter",
      offset: 100,
    };

    var cb = function () {
      scene_controller.removeScene(scene);
      // setTimeout(function () {
      $(trigger_el).each(function (index) {
        var $this = $(this),
          countTo = $this.attr("data-count");
        $({ countNum: $this.text() })
          .delay(500 * index)
          .animate(
            {
              countNum: countTo,
            },
            {
              duration: 1800,
              step: function () {
                var num = Math.floor(this.countNum);
                var withCommas = num
                  .toString()
                  .replace(/\B(?=(\d{3})+(?!\d))/g, ",");
                $this.text(withCommas);
              },
              complete: function () {
                var num = Math.floor(this.countNum);
                var withCommas = num
                  .toString()
                  .replace(/\B(?=(\d{3})+(?!\d))/g, ",");
                $this.text(withCommas);
              },
            }
          );
      });
    };

    var scene = new ScrollMagic.Scene(opts)
      .on("progress", cb)
      .addTo(scene_controller);

    return scene;
  };

  out.countupTrigger = countupTrigger;
  return out;
})();

(function () {
  "use strict";
  // setTimeout for page load.
  setTimeout(function () {
    scroll_utils.countupTrigger(".count");
  }, 200);
})();
